$red: #d03838;

@font-face {
  font-family: "Alpha Echo";
  src: url("../fonts/AlphaEcho.eot");
  src: url("../fonts/AlphaEcho.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AlphaEcho.woff") format("woff"),
    url("../fonts/AlphaEcho.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@mixin cf {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

html,
body {
  &.fixed {
    height: 100%;
    overflow-y: hidden;
  }
}

body {
  min-width: 320px;
  font-family: "Open Sans", sans-serif;
}

// p {
// 	text-align: justify!important;
// }

strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  &.noLink {
    cursor: default;
  }
}

body *,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.vertical-holder {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.vertical-box {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.hidden {
  display: none;
}

.wrapper,
.mobile-wrapper {
  position: relative;
  width: 1156px;
  margin: 0 auto;
}

.section-desktop-menu {
  height: 80px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
  position: relative;

  .wrapper {
    position: relative;
  }
  .logo-text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Alpha Echo", cursive;
    font-size: 25px;
    // padding-left: 50px;
    color: #333;
    line-height: 80%;
    z-index: 50;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: -1px;
    //   left: 0;
    //   display: block;
    //   width: 42px;
    //   height: 42px;
    //   background: url('../img/dd-logo.svg') no-repeat center / cover;
    // }
  }

  .login-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    height: 80px;
    text-align: center;
    .btn-login {
      position: relative;
      top: 50%;
      width: 100%;
      height: 100%;
      color: #fff;
      background: #333;
      font-weight: 700;
      font-size: 14px;
      transition: all 0.2s;
      transform: translateY(-50%);
      border: none;
      z-index: 50;
      &:hover,
      &.active {
        background: $red;
      }
    }
  }
  .login-hidden {
    top: 80px;
  }
}

.login-hidden {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 20px;
  background: #333;
  text-align: left;
  transition: all 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 15;

  &.login-visible {
    opacity: 1;
    visibility: visible;
  }
  form {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  label {
    position: relative;
    display: block;
    margin-bottom: 25px;
    font-size: 14px;
    color: #fff;
  }
  span {
    font-weight: 600;
  }
  input {
    width: 100%;
    border-radius: 2px;
    font-size: 13px;
    color: #fff;
  }
  input[type="email"],
  input[type="password"] {
    margin-top: 3px;
    padding: 10px;
    background-color: #454545;
    border: solid 1px rgba(255, 255, 255, 0.25);
    &:focus {
      outline: #fff;
      border-color: #fff;
    }
  }
  input[type="submit"] {
    margin-top: 5px;
    padding: 13px 0;
    text-align: center;
    border: none;
    background-color: $red;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
    &:focus,
    &:hover {
      background: #fff;
      outline: #fff;
      color: $red;
      box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.error-txt {
  display: none;
}

.error-input {
  input[type="email"],
  input[type="password"] {
    border: 1px solid red;
  }
  .error-txt {
    display: block;
    position: absolute;
    bottom: -18px;
    left: 0;
    font-size: 12px;
    font-weight: 600;
    color: red;
  }
}

.nav-desktop {
  position: relative;
  height: 80px;
  text-align: center;
  z-index: 20;
  > li {
    padding: 30px 0;
    text-align: left;
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    &:not(:last-child) {
      margin-right: 40px;
    }
    &:hover {
      ul {
        display: block;
      }
    }
    a {
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
        color: #333;
      }
      &.arrow-down:hover:after {
        border-color: #333 transparent transparent transparent;
      }
    }
  }
  a {
    color: $red;
  }
  ul {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    width: 140px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    li {
      display: block;
      margin-right: 0;
      font-size: 14px;
      font-weight: 600;
      &:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
      }
    }
    a {
      display: block;
      height: 50px;
      padding: 15px 0 15px 20px;
      background: #fff;
      color: #666;
      &:hover {
        background: #f7f7f7;
        color: #333;
      }
    }
  }
  .arrow-down:after {
    right: -2px;
  }
}

.arrow-down {
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 3px 0 3px;
    border-color: $red transparent transparent transparent;
  }
}

.section-mobile-menu {
  display: none;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  font-size: 0;
  .btn-menu {
    float: left;
    width: 60px;
    height: 60px;
    background: url("../img/icon-lines.svg") #fff no-repeat center;
    &.active {
      background: url("../img/icon-close.svg") #333 no-repeat center;
    }
  }
  .logo-text {
    position: relative;
    font-size: 18px;
    display: inline-block;
    height: 60px;
    font-size: 16px;
    color: #333;
    line-height: 100%;
    padding-top: 15px;
    font-family: "Alpha Echo", cursive;
    padding-left: 10px;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 14px;
    //   left: 5px;
    //   display: block;
    //   width: 32px;
    //   height: 32px;
    //   background: url('../img/dd-logo.svg') no-repeat center / cover;
    // }
  }
  .btn-user {
    float: right;
    width: 60px;
    height: 60px;
    background: url("../img/icon-user1.svg") #333 no-repeat center;
    &.active {
      background: url("../img/icon-user2.svg") $red no-repeat center;
    }
  }
  button {
    border: none;
    display: inline-block;
  }
  .login-hidden {
    top: 60px;
  }
}

.menu-hidden {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 15;
  &.menu-visible {
    display: block;
  }
}

.nav-mobile {
  font-size: 14px;
  > li {
    display: block;
    border-bottom: 1px solid #f3f3f3;
    a {
      height: 50px;
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 17px 0 17px 20px;
      color: $red;
      font-weight: 700;
      font-size: 16px;
      &.arrow-down:after {
      }
      &.active {
        background: #f7f7f7;
        color: #333;
      }
    }
  }
  ul {
    margin-left: 20px;
    list-style-type: none;
    &.visible li {
      display: block;
    }
    li {
      display: none;
    }
    a {
      color: #666;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .arrow-down:after {
    right: 15px;
  }
}

// FOOTER

.footer-institutions {
  background: #f7f7f7;
}

.fi-row {
  font-size: 0;
  padding: 30px 0 15px;
  border-top: 1px solid #dedede;
  h4 {
    margin-bottom: 15px;
    opacity: 0.75;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #666666;
  }
}

.fi-box {
  display: inline-block;
  width: 300px;
  height: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  }
  img {
    width: 100%;
  }
}

.footer-dd {
  padding: 25px 0 35px;
  color: #fff;
  font-size: 0;
  background: #333333 no-repeat left center;
  a {
    color: #fff;
  }
}

.fdd-top,
.fdd-bottom {
  text-align: center;
}

.fdd-top {
  position: relative;
  padding-bottom: 20px;
  font-family: "Alpha Echo", cursive;
  font-size: 21px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #414141;
  span {
    text-align: center;
  }
}

.fdd-bottom {
  max-width: 470px;
  margin: 0 auto;
  padding-top: 22px;
  padding-bottom: 30px;
  font-size: 12px;
  line-height: 133%;
  font-weight: 600;
  color: #999;
  a {
    display: inline-block;
    margin-top: 5px;
    font-weight: 400;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.65;
    }
  }
}

.fddb-left {
  float: left;
  width: 133px;
  margin-right: 25px;
}

.fddb-right {
  float: left;
  width: 310px;
  margin-top: 4px;
}

.page-404 {
  height: 510px;
  background: url("../img/desktop-header.jpg");
  font-size: 0;
}

.left-404 {
  font-size: 230px;
  font-weight: 800;
  color: #fff;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.35);
  text-align: center;
}

.right-404 {
  .inner-txt-404 {
    width: 410px;
    margin: 0 auto;
    padding: 30px 30px 20px 30px;
    text-align: center;
    background: #fff;
    color: #333;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  }
  h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    opacity: 0.75;
    margin-bottom: 20px;
  }
  .back-404 {
    display: block;
    font-size: 18px;
    color: $red;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  .login-404 {
    font-size: 14px;
  }
}

.home-hero {
  height: 320px;
  background: url("../img/desktop-header.jpg") repeat left;
  font-size: 0;
  .hh-right {
    padding-left: 100px;
  }
}

.hero-image {
  display: block;
  width: 400px;
  height: 308px;
  background: url("../img/home-hero-desktop.png") no-repeat center;
}

.hh-left,
.hh-right,
.left-404,
.right-404 {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}

.hh-full {
  width: 100%;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  h2 {
    position: relative;
    margin-bottom: 35px;
    font-size: 42px;
    line-height: 130%;
    font-family: "Alpha Echo", cursive;
    font-weight: 400;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 60px;
      height: 2px;
      background: #fff;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5);
    }
  }
  span {
    display: block;
    font-size: 24px;
    line-height: 125%;
    font-weight: 600;
    font-style: italic;
  }
}

.hh-left {
  font-size: 16px;
  img {
    display: block;
    margin: 0 auto;
  }
}

.hh-right {
  font-size: 16px;
  color: #fff;
  h2 {
    position: relative;
    font-family: "Alpha Echo", cursive;
    line-height: 130%;
    font-size: 28px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    &:after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 0;
      display: block;
      width: 60px;
      height: 2px;
      background: #fff;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5);
    }
  }
  p {
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    line-height: 150%;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }
}

.flickity-page-dots {
  display: none;
}

.home-about {
  @include cf;
  padding: 100px 0 180px;
  .wrapper {
    position: relative;
  }
}

.ha-holder {
  padding: 60px 0 70px 80px;
  background: #f6f6f6;
  h2 {
    position: relative;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    &:after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 0;
      display: block;
      width: 60px;
      height: 2px;
      background: $red;
    }
  }
  p {
    width: 350px;
    opacity: 0.8;
    font-size: 17px;
    line-height: 159%;
    color: #555;
  }
}

.btn-gray {
  display: inline-block;
  width: 160px;
  height: 42px;
  margin-top: 30px;
  line-height: 42px;
  text-align: center;
  background-color: #333;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  transition: background 0.2s;
  &:hover {
    background: $red;
  }
}

.btn-card {
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #333;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  transition: background 0.2s;
  &:hover {
    background: $red;
  }
}

.ha-img {
  position: absolute;
  width: 50%;
  top: -9px;
  right: 35px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.home-our-team {
  @include cf;
  position: relative;
  height: 490px;
  margin-bottom: 110px;
  text-align: center;
  background: url("../img/bg-ourteam.jpg") repeat-y center;
  font-size: 0;
}

.hot-left {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  width: 28%;
  height: 490px;
  color: #fff;
  h2 {
    position: relative;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    &:after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 0;
      display: block;
      width: 60px;
      height: 2px;
      background: #fff;
    }
  }
  p {
    width: 270px;
    opacity: 0.65;
    font-size: 17px;
    line-height: 159%;
  }
  a:hover {
    background: #fff;
    color: #333;
  }
}

.hot-right {
  padding: 50px 0 0 25px;
  font-size: 16px;
  vertical-align: top;
  display: inline-block;
  text-align: left;
  width: 72%;
  height: 490px;
}

.gallery-box {
  float: left;
  width: 260px;
  height: 355px;
  margin-right: 15px;
  img {
    display: block;
    width: 254px;
    height: 220px;
  }
  .hidden-link {
    width: 254px;
    height: 220px;
  }
}

.gallery-box-inner {
  background-color: #fff;
  width: 254px;
  height: 350px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  &:hover {
    .hidden-link {
      visibility: visible;
      opacity: 1;
    }
  }
}

.hidden-link {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(212, 70, 70, 0.75);
  transition: opacity 0.2s;
  text-align: center;
  color: #fff;
  font-weight: 700;
}

.touch-link {
  display: none;
  font-size: 16px;
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-weight: 700;
  color: #d03838;
}

.gb-info {
  padding: 12px 20px 0 20px;
}

.gbi-name {
  font-weight: 700;
  color: #333;
  margin-bottom: 4px;
  font-size: 15px;
}

.gbi-position {
  color: #333;
  font-size: 12px;
  opacity: 0.75;
  margin-bottom: 7px;
}

.gbi-country {
  font-size: 14px;
  color: #ed1c24;
}

.home-bulletin-board {
  @include cf;
  position: relative;
  height: 460px;
  margin-bottom: 410px;
  padding-top: 60px;
  // background: url('../img/bg-bulletinboard.jpg') repeat-y center;
  background: #f6f6f6;
  font-size: 0;
  .bb-card {
    margin-right: 25px;
    &:first-child {
      margin-left: 60px;
    }
  }
}

.bb-left {
  display: inline-block;
  vertical-align: top;
  width: 28%;
  height: 380px;
  color: #fff;
  h2 {
    position: relative;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    &:after {
      content: "";
      position: absolute;
      bottom: -14px;
      left: 0;
      display: block;
      width: 60px;
      height: 2px;
      background: #fff;
    }
  }
  p {
    width: 270px;
    opacity: 0.65;
    font-size: 17px;
    line-height: 159%;
  }
  a {
    background: #fff;
    color: #333;
    &:hover {
      color: #fff;
    }
  }
}

.bb-full {
  width: 100%;
  height: 380px;
  padding: 30px 0 0;
  margin-top: 0px;
  font-size: 16px;
  vertical-align: top;
  text-align: left;
  display: inline-block;
}

.hbb-heading {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  font-size: 24px;
  font-weight: 700;
  &:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: #d03838;
    opacity: 0.75;
  }
}

.hbb-lead {
  padding-bottom: 30px;
  max-width: 450px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 157%;
  text-align: center;
  opacity: 0.65;
}

.hbb-link {
  display: block;
  max-width: 160px;
  margin: 100px auto 0;
  height: 42px;
  line-height: 42px;
  box-sizing: content-box;
  color: #333;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  // padding: 12px 23px;
  border: 2px solid #333;
  transition: all 0.2s;
  &:hover {
    background-color: #333;
    color: #fff;
  }
}

.bb-right {
  width: 72%;
  height: 380px;
  padding: 30px 0 0;
  font-size: 16px;
  vertical-align: top;
  text-align: left;
  display: inline-block;
}

.bb-card {
  position: relative;
  float: left;
  width: 330px;
  height: 400px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.45);
  }
}

.bbc-header {
  position: relative;
  img {
    width: 100%;
  }
  h4 {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 35px 15px;
    font-size: 18px;
    font-weight: 700;
    line-height: 133%;
    color: #fff;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  }
}

.bbc-body {
  padding: 15px 30px 20px 30px;
  p {
    font-size: 14px;
    line-height: 171%;
    color: #333;
    opacity: 0.75;
    height: 140px;
    overflow: hidden;
  }
  .btn-card {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: calc(100% - 60px);
  }
}

.pages-header {
  height: 170px;
  background: url("../img/desktop-header.jpg") left repeat;
}

.header-txt {
  color: #fff;
  font-size: 28px;
  font-weight: 800;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  line-height: 120%;
}

.header-content {
  font-size: 28px;
  font-weight: 800;
  position: relative;
  margin-bottom: 40px;
  &:after {
    content: "";
    position: absolute;
    width: 250px;
    height: 2px;
    bottom: -15px;
    left: 0;
    background: #ed1c24;
  }
}

.content-intro {
  padding: 45px 0 40px;
}

.ci-lead {
  position: relative;
  padding-right: 250px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 156%;
  font-weight: 600;
  font-style: italic;
  color: #333;
  &:after {
    position: absolute;
    bottom: -20px;
    left: 0;
    display: block;
    content: "";
    width: 250px;
    height: 2px;
    background: #ed1c24;
  }
}

.ci-rows {
  column-count: 2;
  p {
    margin-right: 100px;
    font-size: 18px;
    line-height: 150%;
    color: #555;
    opacity: 0.8;
    margin-bottom: 25px;
  }
}

.img-full {
  display: block;
  width: 100%;
  margin-top: 40px;
}

.img-half {
  display: inline-block;
  width: calc(50% - 20px);
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.img-stats-desktop {
  display: block;
  margin-top: -40px;
}

.img-stats-mobile {
  display: none;
}

.section-downloads,
.section-links {
  h3 {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 260px;
      height: 2px;
      background: #ececec;
    }
  }
}

.section-downloads {
  margin-bottom: 100px;
}

.download-boxes {
  @include cf;
  margin-bottom: 50px;
}

.download-box {
  float: left;
  width: 330px;
  height: 60px;
  margin-right: 24px;
  margin-bottom: 15px;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    .dlb-l {
      background-color: $red;
    }
    .dlb-r {
      background-color: #fff;
    }
  }
}

.dlb-l {
  float: left;
  width: 60px;
  height: 60px;
  background: url("../img/icon-download.svg") no-repeat center #333;
  transition: background 0.2s;
}

.dlb-r {
  float: left;
  width: calc(100% - 60px);
  height: 60px;
  background: #f7eddc;
  transition: background 0.2s;
}

.dlb-title {
  font-weight: 600;
  line-height: 130%;
  color: #333;
  margin-bottom: 3px;
  padding: 0 10px;
  font-size: 13px;
}

.dlb-type {
  font-size: 10px;
  font-weight: 600;
  color: #666;
  padding: 0 10px;
}

.section-team {
  margin-bottom: 140px;
  .wrapper {
    @include cf;
  }
}

.team-member {
  overflow: hidden;
  position: relative;
  float: left;
  margin: 0 25px 30px 0;
  width: 330px;
  height: 390px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.45);
    .icon-admin {
      background-color: #333;
    }
  }
  @media (min-width: 1030px) {
    &:hover {
      .hidden-link {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  img {
    display: block;
    width: 100%;
  }
  .hidden-link {
    width: 330px;
    height: 170px;
  }
}

.icon-admin {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  background: url("../img/icon-admin.svg") no-repeat center $red;
  z-index: 12;
  transition: background-color 0.2s;
  &:hover {
    background-color: #333;
    .icon-admin-hidden {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }
}

.icon-admin-hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  position: absolute;
  right: 48px;
  transform: translateY(-50px);
  width: 105px;
  height: 48px;
  background: #fff;
  color: #333;
  font-weight: 700;
  font-size: 10px;
  line-height: 48px;
  text-align: center;
}

.tm-content {
  padding: 15px 20px 25px;
  color: #333;
}

.team-name {
  margin-bottom: 2px;
  font-weight: 700;
}

.team-position {
  margin-bottom: 12px;
  font-size: 14px;
}

.team-info {
  height: 108px;
  font-size: 14px;
  line-height: 129%;
  color: rgba(51, 51, 51, 0.55);
  overflow: hidden;
}

.member-intro {
  @include cf;
  padding: 50px 0 80px;
}

.mi-image-box {
  overflow: hidden;
  float: left;
  margin-right: 70px;
  position: relative;
  width: 254px;
  height: 280px;
}

.mi-image {
  display: block;
}

.mi-contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 260px;
  height: 60px;
  line-height: 60px;
  background: #333;
  transition: background 0.2s;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  &:hover {
    background: $red;
  }
}

.mi-content-box {
  float: right;
  width: calc(100% - 330px);
  padding-top: 30px;
  padding-right: 150px;
  h3 {
    position: relative;
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
    font-weight: 700;
    &:after {
      display: block;
      position: absolute;
      bottom: -7px;
      left: 0;
      content: "";
      width: 236px;
      height: 2px;
      background: #ed1c24;
    }
  }
  .mi-position {
    display: block;
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 156%;
    opacity: 0.8;
    color: #555;
  }
}

.texts-content {
  margin: 45px 0 60px;
  .ci-rows p {
    color: #555;
    font-size: 18px;
    line-height: 156%;
    margin-bottom: 30px;
  }
}

.section-links {
  margin-bottom: 80px;
  ul {
    margin-left: 20px;
  }
  li {
    margin-bottom: 5px;
    font-size: 18px;
  }
  a {
    color: $red;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: border-color 0.2s;
    &:hover {
      border-bottom: 1px solid $red;
    }
  }
}

.bulletin-board-content {
  margin-bottom: 140px;
  .bb-card {
    margin-bottom: 30px;
    margin-right: 40px;
  }
}

.bb-card-wrapper {
  @include cf;
}

.load-more {
  display: block;
  margin: 30px auto 0;
  transform: translateX(-45px);
  width: 378px;
  height: 54px;
  line-height: 54px;
  font-size: 18px;
  background: #333;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  border: none;
  transition: background 0.2s;
  &:hover {
    background: $red;
  }
}

.texts-wrapper {
  @include cf;
}

.texts-box {
  float: left;
  width: 330px;
  height: 459px;
  margin-right: 40px;
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.45);
  }
}

.tb-head {
  position: relative;
  height: 140px;
  background: url("../img/boxes-bg.jpg") repeat center;
  color: #fff;
  span {
    opacity: 0.65;
    font-size: 12px;
    text-transform: uppercase;
  }
  h3 {
    line-height: 150%;
    font-weight: 700;
    font-size: 16px;
  }
}

.tb-head-inner {
  position: absolute;
  top: 10px;
  left: 0;
  padding: 0 20px 17px 20px;
}

.tb-body {
  position: relative;
  height: 320px;
  overflow: hidden;
  span {
    margin-top: 12px;
    color: #666;
    font-size: 12px;
  }
  .authors {
    margin-bottom: 21px;
    font-weight: 600;
    opacity: 1;
    line-height: 140%;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    color: #333;
    opacity: 0.75;
    line-height: 171%;
  }
  .btn-gray {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 290px;
    height: 42px;
  }
}

.overflow-wrapper {
  height: 162px;
  overflow: hidden;
}

.tb-body-inner {
  padding: 5px 20px;
}

.user-img {
  float: left;
  margin-left: 17px;
}

.menu-r {
  float: left;
  margin-left: 13px;
  span {
    display: block;
    text-align: left;
    line-height: 100%;
  }
  .name {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 600;
  }
  .title {
    font-size: 10px;
    color: #b8b8b8;
  }
}

.logged-menu {
  li {
    padding: 15px 0;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(#d4d1d1, 0.12);
    }
  }
  a {
    display: block;
    color: #fff;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.btn-based {
  width: 100%;
  background: $red;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  display: block;
  padding: 15px 0;
  text-align: center;
  border-radius: 2px;
  transition: all 0.2s;
  &:hover {
    background: #fff;
    color: $red;
  }
}

.content-img {
  width: 100%;
  position: relative;
  top: -160px;
  margin-bottom: -130px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
}

.gmap {
  width: 100%;
  height: 500px;
  margin-bottom: 80px;
}

.cookie-terms {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #fff;
  max-width: 450px;
  padding: 20px 40px;
  color: #333;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
}

.cookies-inner {
  h3 {
    font-size: 20px;
    letter-spacing: 1.3px;
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    line-height: 164%;
    margin-bottom: 22px;
  }
  a {
    color: $red;
    text-decoration: underline;
  }
}

.btn-accept {
  padding: 15px 0;
  background: $red;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  width: 160px;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: background 0.2s;
  width: 100%;
  &:hover {
    background: #333;
  }
}

.protestTable {
  padding: 100px 0 140px;
  border-top: 1px solid #ebebeb;
  margin-top: 100px;
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 45px;
    line-height: 31px;
    color: #333;
  }

  td{
    &:last-of-type{
      svg{
        opacity: 0;
        transition: opacity 300ms;
      }
    }
  }

  table {
    border: 1px solid #ebebeb;
    border-spacing: 0 0;
    margin-top: 35px;
    width: 100%;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  tbody {
    tr {
      transition: box-shadow 300ms;
      &:hover {
        position: relative;
        z-index: 2;
        box-shadow: 0 0 20px 0 rgba($color: #000000, $alpha: 0.08);
        td{
          &:last-of-type{
            svg{
              opacity: 1;
            }
          }
        }
      }

      &:first-of-type {
        td {
          border-top: 0;
        }
      }
    }
  }

  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }

  th {
    font-weight: bold;
    font-size: 14px;
    color: #d03838;
    text-align: left;
    padding: 25px 22px;
    text-transform: uppercase;
    border-bottom: 1px solid #ebebeb;
    svg {
      vertical-align: middle;
      display: inline-block;
      margin-left: 6px;
      transition: transform 300ms;
    }

    &.isAsc {
      svg {
        transform: rotate(180deg);
      }
    }

    &.hasOrder {
      cursor: pointer;
    }
  }

  td {
    border-top: 1px solid #ebebeb;
    padding: 33px 22px;
    font-size: 16px;
    line-height: 22px;
    color: #333;
    vertical-align: middle;
    cursor: pointer;
    &:first-letter {
      text-transform: uppercase;
    }

    &:nth-of-type(1) {
      min-width: 100px;
      width: 100px;
    }

    &:nth-of-type(2) {
      min-width: 150px;
      width: 150px;
    }

    &:nth-of-type(3) {
      min-width: 150px;
      width: 150px;
    }

    &:nth-of-type(4) {
      min-width: 150px;
      width: 150px;
    }

    &:nth-of-type(5) {
      min-width: 170px;
      width: 170px;
    }
  }

  td,
  th {
    &:first-of-type {
      background-color: #fff;
      padding-left: 30px;
      @media screen and (max-width: 768px) {
        position: sticky;
        left: 0;
      }
    }

    &:last-of-type {
      padding-right: 30px;
    }

    &:nth-of-type(6) {
      padding-left: 10px;
      padding-right: 10px;
      min-width: 220px;
      width: 220px;
    }

    &:nth-of-type(7) {
      min-width: 260px;
      width: 260px;
    }
  }
}

.protestTable__Filters {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  div {
    width: 100%;
    max-width: 225px;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
    
    & + div {
      margin-left: 35px;
      @media screen and (max-width: 768px) {
        margin: 20px 0 0;
      }
    }
  }

  select {
    padding: 15px 20px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    border: 1px solid #ebebeb;
    outline: 0;
    width: 100%;
    &::first-letter {
      text-transform: uppercase;
    }

    option {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    span {
      color: #d03838;
      font-weight: normal;
      cursor: pointer;
    }
  }
}

.protestTable__Table {
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    margin-top: 35px;
  }
}

.protestTable__Select {
  position: relative;
  z-index: 1000;
  &.isOpen {
    z-index: 100000;
    ul {
      opacity: 1;
      visibility: visible;
    }

    svg {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow-y: scroll;
    background-color: #fff;
    padding: 12px 0;
    border: 1px solid #ebebeb;
    border-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: transform 300ms;
  }

  select {
    pointer-events: none;
    appearance: none;
  }

  li {
    padding: 12px 20px;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 300ms;
    &:hover {
      background-color: #f7f7f7;
    }

    &.isSelected {
      color: #d03838;
      font-weight: bold;
    }
  }
}

.btn-gray.btn-statistics{
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 30px !important;
  width: auto;
  height: auto;
  svg{
    margin-right: 15px;
  }
}

.protest__EventId {
  padding: 60px 0;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
  color: #333;
  @media only screen and (max-width: 768px) {
    padding-bottom: 30px;
    font-size: 14px;
  }
  a {
    color: #333;
  }

  h2 {
    margin-top: 60px;
    font-size: 28px;
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      margin-top: 25px;
    }
    span {
      font-weight: 500;
    }
  }
}

.protest__ArrowLeft {
  max-width: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.protest__EventDataWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 120px;
  font-size: 16px;
  color: #333;
  line-height: 24px;
  @media only screen and (max-width: 768px) {
    line-height: 20px;
    padding-bottom: 50px;
    flex-direction: column;
  }
  h4 {
    color: #d03838;
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

.protest__EventDataItem,
%protest__EventDataItem {
  margin-bottom: 80px;
  max-width: 530px;
  width: calc(50% - 50px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
    max-width: 300px;
    width: 100%;
    margin-right: 0;
  }
  li {
    display: block;
    & + li {
      margin-top: 10px;
    }
  }

  a{
    color: inherit;
  }

  li,
  span {
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.protest__EventDataItemUnderline {
  text-decoration: underline;
  a {
    display: inline-block;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 5px;
    color: #333;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

#protest__EventDataItemViewAll {
  color: #d03838;
  text-decoration: underline #d03838;
}

.protest__EventLocation {
  padding-bottom: 140px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
  h2 {
    font-weight: bold;
    color: #333;
    font-size: 28px;
    margin-bottom: 35px;
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}

.protest__EventDataItem--MobileNone {
  @extend %protest__EventDataItem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.protest__NewspaperList {
  position: fixed;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: 400ms;
  z-index: 1000000000;
  background-color: white;
  top: 0;
  right: 0;
  width: 480px;
  height: 100vh;
  padding: 45px;
  h3 {
    font-size: 28px;
    font-family: "Open Sans";
    font-size: 28px;
    font-weight: bold;
    color: #d03838;
    margin-bottom: 20px;
  }
}

.protest__NewspaperListCloseButton {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 25px;
  background-color: white;
  transform: rotate(45deg);
  font-size: 0;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 20px;
    background-color: #333;
    height: 3px;
    border-radius: 10px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(90deg);
    width: 20px;
    background-color: #333;
    height: 3px;
    border-radius: 10px;
  }
}

.protest__NewspaperListItem {
  ul {
    li {
      a {
        color: #333;
        text-decoration: underline;
        display: inline-block;
        text-transform: capitalize;
        font-weight: 500;
        margin-top: 10px;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        span {
          color: #333;
        }
      }
    }
  }
}

.overlay {
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 10000;
}

html{
  &.isModalOpen{
    overflow: hidden;
    body{
      overflow: hidden;
    }

    .overlay{
      visibility: visible;
      opacity: 1;
      .protest__NewspaperList {
        transform: translateX(0);
      }
    }
  }
}

