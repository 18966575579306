@media (max-width: 1200px) {
  .wrapper {
    width: 100%;
    padding: 0 30px;
  }
  .mobile-wrapper {
    width: 100%;
  }
  .section-desktop-menu .logo-text {
    margin-left: 10px;
  }
  .home-our-team, .bulletin-board {
    background: none;
  }
  .hot-left {
    float: left;
    background: #d44646;
    padding: 0 20px;
    width: 350px;
    p {
      width: auto;
    }
  }
  .hot-right {
    float: left;
    width: calc(100% - 350px);
    background: #f6f6f6;
  }
  .flickity-prev-next-button.previous {
    left: -75px;
  }
  .flickity-prev-next-button.next {
    left: -24px;
  }
  .home-bulletin-board {
    .bb-full {
      width: calc(100% - 60px);
      margin: 0 30px;
      .bb-card {
        width: 32%;
        margin: 0;
        &:first-child {
          margin: 0;
        }
        &:not(:last-child) {
          margin-right: 2%;
        }
      }
    }
  }
  .bb-left {
    float: left;
    background: #333;
    padding: 0 20px;
    width: 350px;
    p {
      width: auto;
    }
  }
  .bb-right {
    float: left;
    width: calc(100% - 350px);
    background: #f6f6f6;
  }
  .home-bulletin-board .bb-card {
    width: calc(50% - 50px);
    &:first-child {
      margin-left: 30px;
    }
  }
  .mi-content-box {
    padding-right: 0;
  }
  .load-more {
    transform: translateX(0);
    margin-left: 155px;
  }
  .img-stats {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .touch-link {
    display: block;
  }
}

@media (max-width: 1000px) {
  .section-desktop-menu {
    display: none;
  }
  .section-mobile-menu {
    display: block;
  }
  .home-about {
    padding: 30px 0 100px;
    background: #f6f6f6;
  }
  .ha-img {
    display: block;
    position: static;
    width: auto;
    max-width: calc(100% - 60px);
    margin: 0 auto;
  }
  .ha-holder {
    width: 100%;
    padding: 25px 30px;
    p {
      width: auto;
    }
  }
  .bulletin-board {
    height: auto;
  }
  .bb-left {
    height: auto;
    padding: 30px;
  }
  .bb-left, .bb-right {
    width: 100%;
  }
  .home-bulletin-board .bb-card {
    margin-right: 0;
    &:first-child {
      margin-right: 30px;
    }
  }
  .ci-lead {
    padding-right: 100px;
  }
  .ci-rows p {
    margin-right: 50px;
  }
  .download-box {
    margin-bottom: 20px;
  }
  .team-member:nth-child(2n+2) {
    margin-right: 0;
  }
  .mi-image-box {
    margin-right: 40px;
  }
  .mi-content-box {
    padding-top: 0;
    width: calc(100% - 300px);
  }
  .mi-content-box p {
    font-size: 14px;
  }
  .left-404 {
    font-size: 150px;
  }
  .right-404 .inner-txt-404 {
    width: 100%;
  }
  .bulletin-board-content .bb-card {
    &:nth-child(2n+1) {
      margin-right: 25px;
    }
    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
  .texts-box {
    width: 320px;
    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}
@media (max-width: 840px) {
  .home-bulletin-board .bb-full .bb-card {
    width: 48%;
    &:nth-child(3) {
      display: none;
    }
  }
}

@media (max-width: 750px) {
  .home-hero {
    height: 420px;
  }
  .hero-image {
    height: 183px;
    width: 185px;
    background: url('../img/home-hero-mobile.png') no-repeat center / contain;
  }
  .hh-left, .hh-right {
    width: 100%;
  }
  .hh-right {
    padding-left: 0;
  }
  .hot-left, .hot-right {
    width: 100%;
  }
  .home-our-team {
    height: auto;
    margin-bottom: 50px;
  }
  .hot-left {
    height: auto;
    padding: 30px;
  }
  .hot-right {
    height: auto;
    padding-bottom: 60px;
  }
  .flickity-page-dots {
    display: block;
    bottom: -35px;
  }
  .flickity-prev-next-button {
    display: none;
  }
  .load-more {
    width: 330px;
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .img-half {
    display: block;
    width: 100%;
		margin-right: 0;
    margin-bottom: 10px;
  }
  .img-stats-desktop {
    display: none;
  }

  .img-stats-mobile {
    display: block;
  }
  .home-bulletin-board {
    background: none;
  }
  .bulletin-board {
    background: #fef4e3;
    margin-bottom: 60px;
  }
  .bb-right {
    height: auto;
    margin: 0 30px;
    width: calc(100% - 60px);
    background: none;
  }
  .home-bulletin-board .bb-card, .bb-card {
    width: 100%;
    margin: 0;
    height: auto;
    &:first-child {
      margin: 0 0 30px 0;
    }
  }
  .bbc-body p {
    margin-bottom: 60px;
  }
  .ci-lead {
    padding-right: 0;
    font-size: 16px;
    line-height: 138%;
  }
  .download-boxes {
    margin-bottom: 60px;
  }
  .ci-rows {
    column-count: 1;
    p {
      margin-right: 0;
      font-size: 14px;
      line-height: 157%
    }
  }
  .pages-header h1 {
    font-size: 24px;
  }
  .mi-image-box {
    float: none;
    margin: 0 auto;
  }
  .mi-content-box {
    float: none;
    width: 100%;
    margin-top: 40px;
  }
  .texts-content p {
    font-size: 14px;
    line-height: 157%;
  }
  .left-404, .right-404 {
    width: 100%;
  }
  .left-404 {
    font-size: 110px;
  }
  .right-404 {
    h2 {
      font-size: 16px;
    }
    p, .back-404 {
      font-size: 14px;
    }
    .login-404 {
      font-size: 13px;
    }
  }
  .load-more {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .home-bulletin-board .bb-full .bb-card:not(:last-child) {
    margin: 0;
  }
  .home-bulletin-board .bb-full .bb-card {
    width: 100%;
    margin-bottom: 20px!important;
  }
  .home-bulletin-board, .bb-full {
    height: auto;
  }
  .home-bulletin-board {
    margin-bottom: 60px;
  }
  .hbb-link {
    margin-top: 30px;
  }
  // .mobile-wrapper {
  //   background-color: #f6f6f6;
  // }
}

@media (max-width: 550px) {
  .fddb-left, .fddb-right {
    float: none;
    width: 100%;
    text-align: center;
  }
  .fdd-bottom {
    display: block;
    text-align: center;
  }
  a.cc-logo {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .fddb-right {
    text-align: center!important;
  }
}

@media (max-width: 450px) {
  .fi-box {
    width: 100%;
    height: 100%;
    img {
    }
  }
  .download-box {
    margin-right: 0;
    width: 100%;
  }
  .team-member {
    margin-right: 0;
    width: 100%;
  }
  .load-more {
    width: 100%;
  }
  .texts-box {
    margin-right: 0;
    width: 100%;
    height: auto;
    .btn-gray {
      margin: 10px 0 15px;
    }
  }
  .hh-full h2 {
    font-size: 36px;
  }
}
