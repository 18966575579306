$darkgray: #98a4b4;
$error: #ce0000;

body{
	overflow-y: scroll;
}

@mixin grid($colNumber, $margin){
	$percentage: (100/$colNumber) + %;
	$offset: $margin - ($margin/$colNumber) + px;
	display: inline-block;
	width: calc(#{$percentage} - #{$offset});
	&:not(:last-of-type){
		margin-right: $margin + px;
	}
}

*{
	box-sizing: border-box;
}

body, html{
	margin:0;
	padding:0;
}

i{
	font-style: italic;
}

a{
	text-decoration: none;
}

button{
	outline: 0;
	border:0;
	background-color: transparent;
}

b{
	font-weight: 500;
}

ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.container{
	max-width: 1156px;
	margin:0 auto;
	font-size: 0;


	h3{
		font-size: 18px;

	}
}

.errors{
	margin-bottom: 60px;
	h4{
		font-size: 14px;
		font-weight: 600;
		color: #333;
		margin:25px 0 0;
	}

	.single-error{
		border-radius: 4px;
		background-color: #fffafa;
		border: 1px solid #de5757;
		margin-top: 10px;
		position: relative;
		transition:border-color 200ms;
		&:hover{
			border-color: #ce0000;
			button{
				background-color: #ce0000;
			}
		}
		p{
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
			color: #333;
			padding: 11px 19px;
			margin: 0;
			max-width: calc(100% - 150px);
		}

		button{
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			color: #fff;
			padding: 0 20px;
			background-color: #de5757;
			transition:background-color 200ms;
		}
	}
}

.location{
	background-image: url('../img/icons/location.png');
	background-size: 17px 22px;
	background-repeat: no-repeat;
	background-position: 345px center;
}

.datepicker, .datepicker-week{
	background-image: url('../img/icons/calendar.png');
	background-size: 24px 22px;
	background-repeat: no-repeat;
	background-position: 340px center;
}

.map-wrapper{
	width: 440px;
	height: 240px;
	display: inline-block;
	position: absolute;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	visibility: hidden;
	z-index: 10;
	&:not(.hidden){
		opacity: 1;
		visibility: visible;
		display: inline-block;
		vertical-align: top;
		margin-left: 20px;
		.map{
			& + input[type="text"]:not([readonly="readonly"]){
				&.search{
					display: block;
				}
			}
		}
	}
	.map{
		width: 440px;
		height: 240px;
	}
	.close-map{
		font-size: 0;
		width: 27px;
		height: 27px;
		background-color: $darkgray;
		position: absolute;
		right: 10px;
		top: 10px;
		border-radius: 50%;
		cursor:pointer;
		transition:background-color 200ms, opacity 200ms;
		backface-visibility: hidden;
		&:hover{
			background-color: #bcc4ce;
			opacity: 0.95;
		}
	}
}

.dropdown{
	margin-bottom: 20px;
}

.dropdown, input[type="text"]:not([readonly="readonly"]){
	width: 380px;
	border-radius: 4px;
	border: solid 1px $darkgray;
	position: relative;
	padding:11px 0;
	min-height: 46px;
	transition:border-color 200ms;


	&.block{
		display: block;
		margin-top: 20px;
	}

	&.error{
		border-color: $error;
		&:after{
			background-color: $error;
		}
		input[type="text"][readonly="readonly"]{
			color:$error;
			&::-webkit-input-placeholder {
				color: $error;
			}
			&:-moz-placeholder {
				color: $error;
				opacity:  1;
			}
			&::-moz-placeholder {
				color: $error;
				opacity:  1;
			}
			&:-ms-input-placeholder {
				color: $error;
			}
			&::-ms-input-placeholder {
				color: $error;
			}
		}
	}

	&.opened{
		.li-wrapper{
			opacity: 1;
			visibility: visible;
		}

		&:before{
			transform:translate(-50%, -50%) rotate(180deg);
		}
	}

	&:not(.multiple-choice){
		li{
			padding: 10px 10px;
		}
	}

	&.multiple-choice{
		input[type="checkbox"]{
			display: none;
			&:checked + .checkbox{
				background-color: rgba(152, 164, 180, 0.06);
				color:#333;
				&:before{
					background-image: url('../img/icons/check.png');
				}
			}
		}

		.checkbox{
			cursor: pointer;
			width: 100%;
			height: 100%;
			display: block;
			padding: 10px 10px;
			transition:background-color 200ms, color 200ms;
			&:before{
				content: "";
				display: inline-block;
				vertical-align: middle;
				margin-top: -3px;
				margin-right: 15px;
				width: 17px;
				height: 17px;
				background-color: #f2f2f2;
				border: 1px solid $darkgray;
				background-size: 12px 10px;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
	}

	&:hover{
		border-color: #5b6778;
		&:after{
			background-color: #5b6778;
		}
	}

	&:after{
		position: absolute;
		content: "";
		width: 46px;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: $darkgray;
		transition:background-color 200ms;
		backface-visibility: hidden;
		cursor:pointer;
	}

	&:before{
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 6px 0 6px;
		border-color: #ffffff transparent transparent transparent;
		right: 11px;
		top: 50%;
		transform:translate(-50%, -50%);
		z-index: 2;
		transition:transform 200ms;
		backface-visibility: hidden;
		cursor:pointer;
	}

	.checkbox, input[type="text"][readonly="readonly"], li{
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		text-align: left;
	}

	input[type="text"][readonly="readonly"]{
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		width: calc(100% - 46px);
		border:0;
		color: #333;
		font-family: 'Open Sans', sans-serif;
		padding: 0 12px;
		background-color: #fff;
		border-radius: 4px;
		min-height: 42px;
		line-height: 42px;

		&::-webkit-input-placeholder {
			color: $darkgray;
		}
		&:-moz-placeholder {
			color: $darkgray;
			opacity:  1;
		}
		&::-moz-placeholder {
			color: $darkgray;
			opacity:  1;
		}
		&:-ms-input-placeholder {
			color: $darkgray;
		}
		&::-ms-input-placeholder {
			color: $darkgray;
		}
	}

	.li-wrapper{
		position: absolute;
		border:1px solid $darkgray;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
		top: calc(100% + 5px);
		left: -1px;
		right: -1px;
		visibility: hidden;
		opacity: 0;
		transition:opacity 200ms, visibility 200ms;
		max-height: 330px;
		overflow-y: scroll;
		background-color: #fff;
		z-index: 3;
		backface-visibility: hidden;
		&::-webkit-scrollbar {
			width: 9px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $darkgray;
		}
	}

	li{
		display: block;
		color: $darkgray;
		transition:color 200ms;
		cursor:pointer;
		font-weight: 600;
		backface-visibility: hidden;
		&:hover{
			color:#333;
		}
	}
}

.datepicker, .datepicker-week{
	margin-bottom: 20px;
}

.ui-datepicker{
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px $darkgray;
	padding: 0 2px;
	position: relative;
	padding-top: 20px;
	padding-bottom: 10px;
	.ui-datepicker-title{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 8px 0;
		background-color: $darkgray;
		text-align: center;
	}
	.ui-datepicker-next, .ui-datepicker-prev{
		position: absolute;
		font-size: 0;
		top: 7px;
		width: 24px;
		height: 24px;
		background-color: #fff;
		border-radius: 50%;
		transition:opacity 200ms;
		z-index: 3;
		cursor: pointer;
		&:before{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform:translate(-50%, -50%);
			transition: border-color 200ms;
		}
		&:hover{
			background-color: #cbd1d9;
		}
	}
	.ui-datepicker-next{
		right: 8px;
		&:hover{
			&:before{
				border-left-color: #fff;
			}
		}
		&:before{
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3px 0 3px 3px;
			border-color: transparent transparent transparent #666666;
		}
	}

	.ui-datepicker-prev{
		left: 8px;
		&:hover{
			&:before{
				border-right-color: #fff;
			}
		}
		&:before{
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 3px 3px 3px 0;
			border-color: transparent #666666 transparent transparent;
		}
	}
	.ui-datepicker-month{
		color:#333;
		font-size: 15px;
		font-weight: 700;
	}
	.ui-datepicker-year{
		color:#fff;
		font-size: 15px;
	}
	.ui-state-default{
		text-align: center;
		color: $darkgray;
		font-size: 12px;
		font-weight: bold;
		transition:color 200ms;
		display: block;
	}

	th{
		font-size: 12px;
		font-weight: bold;
		color: #665;
	}

	td{
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 50%;
		transition:background-color 200ms;

		&:hover, &.ui-state-hover{
			background-color: $darkgray;
			.ui-state-default{
				color:#fff;
			}
		}
	}

	.ui-priority-secondary{
		opacity: 0.5;
	}

	.ui-datepicker-week-col{
		display: none;
	}

	.ui-state-active{
		font-size: 12px;
		display: block;
	}

	.ui-datepicker-current-day{
		background-color: #5b6778;
		border-radius: 50%;
		a{
			color:#fff;
		}
	}
}

.default-input{
	margin-bottom: 20px;
}
.form-wrapper{
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 70px;

	.edit-boxes{
		margin-bottom: 20px;
		.box{
			width: 160px;
			min-height: 85px;
			border-radius: 4px;
			background-color: #5b6778;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
			padding: 12px 0;
			display: inline-block;
			vertical-align: middle;
			position: relative;
			&:not(:last-child){
				margin-right: 25px;
			}

			h5{
				font-size: 12px;
				font-weight: 600;
				text-align: center;
				margin:0;
				color:#fff;
			}

			.close{
				width: 20px;
				height: 20px;
				background-color: #fff;
				box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
				border-radius: 50%;
				position: absolute;
				right: 0;
				top: 0;
				transform:translate(25%, -25%);
				cursor: pointer;
				background-position: center center;
				background-size: 10px 10px;
				background-repeat: no-repeat;
				background-image: url(../img/icons/card-close.png);
				transition: background-color 200ms, background-image 200ms;
				backface-visibility: hidden;

				&:hover{
					background-color: $darkgray;
					background-image: url('../img/icons/card-close-hover.png');
				}
			}
		}
	}


	.single-input-wrapper{
		padding:0 20px;
		position: relative;
		margin:0 -20px 20px;
		border-bottom: 1px solid #bbb;
	}

	.single-inner{
		background-color: #fcfcfc;
		position: relative;
		margin: 0 -20px;
		padding: 20px 20px 20px;
		transition:background-color 200ms;
		backface-visibility: hidden;
		&.active{
			background-color: #f4f5f6;
		}

		h4{
			padding-bottom: 15px;
		}
	}
	.row-controls{
		position: absolute;
		right: 20px;
		top: 50%;
		transform:translateY(-50%);
	}

	.addRow, .removeRow{
		display: inline-block;
		vertical-align: middle;
		width: 22px;
		height: 22px;
		background-color: $darkgray;
		transition:background-color 200ms;
		border-radius: 50%;
		color:#fff;
		backface-visibility: hidden;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center center;
		font-size: 0;
		&:not(:last-child){
			margin-right: 6px;
		}
		&:disabled{
			opacity: 0.5;

		}
		&:hover{
			&:not(:disabled){
				background-color: #5b6778;
			}
		}
	}

	.addRow{
		background-image: url('../img/icons/plus.png');
		background-size: 12px 12px;
	}

	.removeRow{
		background-image: url('../img/icons/minus.png');
		background-size: 12px 2px;
	}

	.label{
		font-size: 14px;
		font-weight: 600;
		text-align: left;
		color: #665;
		display: block;
		margin-bottom: 12px;

		& + span{
			font-size: 14px;
			color: #98a4b4;
			display: block;
			margin-top: -8px;
			margin-bottom: 20px;
		}
	}

	.input-wrapper{
		display: inline-block;
		vertical-align: top;
		&:not(:last-of-type){
			margin-right: 45px;
		}

		&:not(.hidden){
			&.block{
				width: 100%;
			}
		}

		&.active{
			&:not(.block){
				display: inline-block;
				vertical-align: top;
			}
			&.block{
				display: block;
			}
		}

		&.upload{
			margin-top: 20px;
			display: block;
			position: relative;
			input{
				display: none;
			}

			&.active{
				display: block;
			}
		}

		&.hidden{
			display: none;
			&.active{
				&.block{
					display: block;
				}
			}
		}
	}

	.hidden-template{
		display: none;
	}

	.week-wrapper{
		position: relative;
		.week-label{
			position: absolute;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
			text-align: left;
			color:#333;
			position: absolute;
			left: 12px;
			top: 50%;
			transform:translateY(calc(-50% - 10px));
			pointer-events:none;
		}
	}

	textarea{
		border-radius: 4px;
		background-color: #fff;
		border: 1px solid #98a4b4;
		width: 100%;
		font-family: 'Open Sans', sans-serif;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		text-align: left;
		color:#333;
		outline: 0;
		padding: 12px;
		min-height: 190px;
		resize: none;
		margin-bottom: 20px;
	}

	.remove-image{
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 0.75);
		border: 1px solid #98a4b4;
		font-size: 12px;
		font-weight: 600;
		text-align: left;
		color: #98a4b4;
		display: block;
		padding: 9px 20px;
		text-transform: uppercase;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 168px;
		transition:border-color 200ms, color 200ms;
		cursor:pointer;
		&:hover{
			color:#5b6778;
			border-color: #5b6778;
		}
	}

	.upload-input{
		border-radius: 4px;
		background-color: #fff;
		border: solid 1px #98a4b4;
		display: block;
		position: relative;
		min-height: 46px;
		line-height: 46px;
		width: 380px;
		padding: 0 12px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 600;
		color:#98a4b4;
		font-size: 16px;
		transition: border-color 200ms;

		&:hover{
			&:after{
				background-color: #5b6778;
			}
		}

		&:after{
			position: absolute;
			content: "";
			width: 46px;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: $darkgray;
			transition:background-color 200ms;
			backface-visibility: hidden;
			cursor:pointer;
			background-image:url('../img/icons/dots.png');
			background-position: center 30px;
			background-repeat: no-repeat;
			background-size: 15px 3px;

		}

		& + a{
			font-size: 12px;
			font-weight: 600;
			line-height: 1.33;
			text-decoration: underline;
			color: #5b6778;
			display: block;
			max-width: 380px;
			word-break: break-all;
			margin-top: 20px;
		}
	}

	input[type="text"]:not([readonly="readonly"]){
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		text-align: left;
		color:#333;
		outline: 0;
		padding: 0 12px;
		font-family: 'Open Sans', sans-serif;

		&.datepicker-week{
			font-size: 0;
		}

		&.search{
			width: 410px;
			border-radius: 4px;
			background-color: #fff;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
			border: solid 1px #98a4b4;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
			color: #98a4b4;
			position: absolute;
			bottom: 15px;
			left: 15px;
			right: 15px;
			display: none;
			font-family: 'Open Sans', sans-serif;
		}

		&.hidden{
			display: none;
		}
	}

	.half{
		@include grid(2, 24);
	}

	.single-block{
		border-radius: 4px;
		background-color: #fcfcfc;
		border: solid 1px #f0eded;
		margin-bottom: 15px;
		padding: 15px 20px 0;
		position: relative;

		&.submit{
			input[type="submit"]{
				width: 220px;
				padding: 14px 0;
				border-radius: 2px;
				font-size: 13px;
				font-weight: 600;
				text-align: center;
				color: #fff;
				display: inline-block;
				vertical-align: middle;
				text-transform: uppercase;
				border:0;
				outline: 0;
				margin-bottom: 30px;
				transition: background-color 200ms;
				&:not(:last-of-type){
					margin-right: 20px;
				}

				&:nth-child(2){
					background-color: #d44646;
					&:hover{
						background-color: #333;
					}
				}

				&:nth-child(3){
					background-color: #aeaeae;
					&:hover{
						background-color: #6e5d89;
					}
				}
			}

			h4{
				font-size: 20px;
				margin: -5px 0 17px;
			}
		}

		fieldset{
			display: none;
		}

		hr{
			display: block;
			border:0;
			height: 1px;
			opacity: 0.25;
			background-color: #bbb;
			margin: 15px 0;
		}


		.help{
			position: absolute;
			top: 15px;
			right: 15px;
			.icon{
				display: inline-block;
				vertical-align: middle;
				width: 23px;
				height: 23px;
				background-color: #dee4ec;
				border-radius: 50%;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
				transition:background-color 200ms;
				backface-visibility: hidden;
				&:hover{
					background-color: $darkgray;
				}
				&:not(:last-child){
					margin-right: 5px;
				}
				&.comment{
					background-image: url('../img/icons/comment.png');
					background-size: 12px 12px;
				}
				&.tooltip{
					background-image: url('../img/icons/tooltip.png');
					background-size: 8px 12px;
					background-position: 7px 5px;

					&:hover{
						& + .tooltip-description{
							visibility: visible;
							opacity: 1;
						}
					}
				}
			}

			.tooltip-description{
				width: 290px;
				background-color: #98a4b4;
				padding: 15px 20px;
				position: absolute;
				z-index: 2;
				right: -5px;
				top: 45px;
				visibility: hidden;
				opacity: 0;
				transition:visibility 200ms, opacity 200ms;

				&:after{
					position: absolute;
					content: "";
					width: 30px;
					height: 30px;
					background-color: #98a4b4;
					transform:skewY(-45deg);
					right: 25px;
					top: -5px;
					z-index: -1;
				}
				p{
					font-size: 10px;
					line-height: 1.6;
					text-align: left;
					color: #fff;
					margin: 0;
				}
			}
		}

		&.half{
			h3{
				margin-bottom: 4px;
			}
		}

		h3{
			font-size: 14px;
			font-weight: 600;
			text-align: left;
			color: #665;
			margin:0 0 10px;

			& + span{
				font-size: 14px;
				text-align: left;
				color: $darkgray;
				display: block;
				margin-top: -5px;
				margin-bottom: 20px;
			}

			& + h4{
				margin-bottom: 15px;
			}
		}

		h4{
			font-size: 18px;
			font-weight: bold;
			text-align: left;
			color: #333;
			margin: 0;
		}

		.multiple-choice-single{
			border-bottom:1px solid rgba(187, 187, 187, 0.25);
			padding:15px 0;
			margin-bottom: 20px;
			margin-top: -20px;

			&:nth-child(7){
				margin-top: 15px;
				border-top:1px solid rgba(187, 187, 187, 0.25);
			}
			h4{
				margin-bottom: 15px;
			}
		}
	}
}

.go-back{
	position: absolute;
	right: 30px;
	bottom: 30px;
	cursor: pointer;
	color:#fff;
	width: 52px;
	height: 52px;
	background-color: #98a4b4;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	font-size: 0;
	background-image: url('../img/icons/to-top.png');
	background-size: 22px 24px;
	background-position: center center;
	background-repeat: no-repeat;
	backface-visibility: hidden;
	transition:background-color 200ms;

	&:hover{
		background-color: #5b6778;
	}
}

.edit{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255,0.25);
	color:#fff;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	display: block;
	width: 100%;
	text-transform: uppercase;
	padding: 6px 0;
	cursor: pointer;
}
