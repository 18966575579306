
.input-history, .personal-info, .about-form{
	font-family: 'Open Sans';
	h2{
		font-size: 24px;
		font-weight: bold;
		text-align: left;
		color: #333;
		margin: 0;
		padding-bottom: 13px;
	}
}

#ui-datepicker-div{
	display: none;
}

.input-history, .personal-info{
	h2{
		border-bottom: 1px solid #bbb;
	}
}

.form-wrapper{
	h2{
		font-size: 24px;
		font-weight: bold;
		text-align: left;
		color: #333;
		margin: 0 0 10px;
		padding-bottom: 13px;
		border-bottom: 1px solid #bbb;

	}
}

.input-history{
	position: relative;
	h2{
		padding-bottom: 23px;
	}
	.button{
		display: inline-block;
		width: 120px;
		font-size: 13px;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		border-radius: 2px;
		border:0;
		outline: 0;
		padding: 12px 0;


		&:not(:last-of-type){
			margin-right: 20px;
		}
	}

	.search-download{
		position: absolute;
		right: 0;
		top: 0;
		input[type="text"]{
			font-size: 14px;
			min-height: 42px;
			line-height: 42px;
			padding: 0 10px;
			font-weight: 600;
			text-align: left;
			color: #5b6778;
			border-color:#bbb;
			outline: 0;
			vertical-align: middle;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right:0;
		}
		input[type="submit"]{
			width: 44px;
			height: 44px;
			display: inline-block;
			vertical-align: middle;
			border:0;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
			background-color: $darkgray;
			background-image: url('../img/icons/search.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 14px 14px;
			transition:background-color 200ms;
			&:hover{
				background-color: #5b6778;
			}
		}
	}

	.buttons{
		display: block;
		position: absolute;
		min-height: 68px;
		background-color: #f3f3f3;
		left: 0;
		right: 0;
		bottom: -68px;
		font-size: 0;
		.input-wrapper{
			position: absolute;
			right: 20px;
			top: 50%;
			transform:translateY(-50%);
		}
		.button{

			&:first-child{
				background-color: #98a4b4;
				color:#fff;
				transition:background-color 200ms;
				&:hover{
					background-color: #5b6778;
				}
			}
			&:nth-child(2){
				background-color: rgba(255, 255, 255, 0.75);
				border: solid 1px #98a4b4;
				color:#98a4b4;
				transition: border-color 200ms, color 200ms;
				&:hover{
					border-color: #5b6778;
					color:#5b6778;
				}
			}
		}
	}
}

.about-form{
	margin-top: 50px;
	.border-bottom{
		border-bottom: 1px solid #bbb;
	}
	.text-wrapper{
		margin-bottom: 60px;
		p{
			font-size: 14px;
			line-height: 1.57;
			text-align: left;
			color: #666;
			column-count: 2;
			column-gap: 60px;
			margin:0;
		}
	}

	.single-block{
		border-radius: 4px;
		background-color: #fcfcfc;
		border: solid 1px #f0eded;
		padding: 20px;

		h3{
			font-size: 14px;
			font-weight: 600;
			text-align: left;
			color: #666;
			margin: 0;
		}
		span{
			font-size: 14px;
			text-align: left;
			color: #98a4b4;
			display: block;
			margin: 4px 0 25px;
		}

		.btn-red{
			display: block;
			font-size: 13px;
			font-weight: 600;
			text-align: center;
			color: #fff;
			border-radius: 2px;
			background-color: #d44646;
			width: 220px;
			padding: 14px 0;
			margin-top: 25px;
			text-transform: uppercase;
			transition: background-color 200ms;
			&:hover{
				background-color: #333;
			}
		}
	}

	.message{
		.message-wrapper{
			text-align: center;
			border-radius: 4px;
			background-color: #fcfcfc;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
			padding: 45px 0 50px;
			margin-top: 30px;

			h2{
				text-align: center;
				margin: 0;
			}

			p{
				font-size: 20px;
				line-height: 1.5;
				color: #666;
				margin: 25px auto 50px;
				max-width: 685px;
			}

			.btn-red{
				display: block;
				margin:0 auto;
				width: 220px;
				border-radius: 2px;
				background-color: #d44646;
				font-size: 13px;
				font-weight: 600;
				text-align: center;
				color: #fff;
				padding: 14px 0;
				text-transform: uppercase;

				& + span{
					font-size: 16px;
					line-height: 1.88;
					color: #666;
					display: block;
					margin: 10px 0 5px;
				}
			}

			.link{
				font-size: 14px;
				line-height: 1.57;
				color: #d44646;
				text-decoration: underline;
			}
		}
	}
}

.add-new-input{
	background-color: #333;
	font-family: 'Open Sans';
	padding: 30px;
	position: relative;
	margin: 50px 0 60px;
	p{
		font-size: 18px;
		font-weight: 600;
		line-height: 1.44;
		text-align: left;
		color: #fff;
		max-width: 485px;
		margin: 0;
	}

	a{
		display: block;
		width: 220px;
		padding: 15px 0;
		border-radius: 2px;
		background-color: #d03838;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		font-size: 13px;
		font-weight: bold;
		text-align: center;
		color: #fff;
		text-transform: uppercase;
		position: absolute;
		right: 30px;
		top: 50%;
		transform:translateY(-50%);
		backface-visibility: hidden;
		transition: color 200ms, background-color 200ms, box-shadow 200ms;
		&:hover{
			color:#d03838;
			background-color: #ffffff;
			box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.5), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		}
	}
}

.personal-info{
	.info-wrapper{
		margin-top: 15px;
		margin-bottom: 100px;
	}
	.single{
		border-radius: 4px;
		background-color: #fcfcfc;
		border: 1px solid #f0eded;
		padding: 20px 15px;
		display: inline-block;
		vertical-align: top;
		width: calc(50% - 12px);
		margin-bottom: 10px;
		&:not(:nth-child(2n)){
			margin-right: 24px;
		}
		h3{
			font-size: 18px;
			font-weight: bold;
			color: #333;
			margin: 4px 0 0;
		}

		label{
			font-size: 14px;
			text-align: left;
			color: #666;
		}
	}
}

.input-history{
	.filter{
		position: relative;
		min-height: 68px;
		background-color: #f9f9f9;

		font-size: 0;
		padding: 5px 20px;
		margin-bottom: 98px;
		&:before{
			content: "";
			vertical-align: middle;
			display: inline-block;
			min-height: 68px;
		}
		h4, label{
			font-size: 14px;
			font-weight: bold;
			text-align: left;
			display: inline-block;
			vertical-align: middle;
		}

		h4{
			color: #333;
		}

		label{
			color: #666;
		}

		.filter-wrapper{
			position: absolute;
			right: 20px;
			top: 50%;
			transform:translateY(-50%);

			.single-filter{
				display: inline-block;
				vertical-align: middle;
				&:not(:last-of-type){
					margin-right: 20px;
				}

				select{
					font-size: 14px;
					font-weight: 600;
					text-align: left;
					color: #333;
					border:1px solid #bbb;
					background-color: #fcfcfc;
					margin-left: 10px;
					border-radius: 4px;
					display: inline-block;
					vertical-align: middle;
					padding: 12px 16px;
				}
			}
		}
	}

	.pagination{
		margin: 40px 0 100px;
		text-align: center;
		span{
			font-size: 14px;
			font-weight: 600;
			display: inline-block;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			line-height: 32px;
			cursor: pointer;
			transition: background-color 200ms;
			&:not(.active){
				background-color: #e7e7e7;
				color:#333;

				&:hover{
					background-color: #bbb;
				}
			}
			&.active{
				background-color: #333;
				color: #fff;
			}

			&:not(:last-of-type){
				margin-right: 10px;
			}
		}
	}
}

.single-history{

	border-radius: 4px;
	background-color: #fcfcfc;
	border: solid 1px #f0eded;
	padding:15px 20px;
	margin-bottom: 10px;
	position: relative;
	p{
		font-size: 14px;
		text-align: left;
		color: #666;
		margin:0;
	}

	h3{
		font-size: 18px;
		font-weight: bold;
		text-align: left;
		color: #333;
		margin:4px 0 15px;
	}

	.new-input{
		position: absolute;
		top: 15px;
		right: 20px;
		display: block;
		width: 92px;
		border-radius: 100px;
		background-color: #f1f2f4;
		font-size: 11px;
		font-weight: bold;
		text-align: center;
		color: #ce0000;
		text-indent: 7px;
		padding: 2px;
		text-transform: uppercase;
		&:before{
			position: absolute;
			top: 50%;
			left: 8px;
			transform:translateY(-50%);
			content: "";
			display: block;
			width: 6px;
			height: 6px;
			background-color: #ce0000;
			border-radius: 50%;
		}
	}

	.bottom{
		border-radius: 4px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		background-color: #f1f2f4;
		border-top: solid 1px #f0eded;
		margin:0 -20px -15px -20px;
		padding:10px 20px;
		position: relative;

		a{
			display: block;
			width: 100px;
			padding: 10px 0;
			border-radius: 2px;
			background-color: #98a4b4;
			font-size: 13px;
			font-weight: 600;
			text-align: center;
			color: #fff;
			text-transform: uppercase;
			transition: background-color 200ms;
			&:hover{
				background-color: #5b6778;
			}
		}

		label{
			position: absolute;
			right: 20px;
			top: 50%;
			transform:translateY(-50%);
			padding: 5px 15px;
			font-size: 12px;
			font-weight: 600;
			text-align: left;
			color: #fff;
			text-transform: uppercase;

			&.finished{
				background-color: #00ca11;
			}

			&.not-reviewed{
				background-color: #ffa600;
			}

			&.draft{
				background-color: #6e5d89;
			}

			&.corrections{
				background-color: #cf0000;
			}
		}
	}
}
